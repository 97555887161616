define(['app', '$window', 'accessibleModalHelper', 'accessibilityFocusHelper'], function (app, $window, accessibleModalHelper, accessibilityFocusHelper) {

  const createEvent = (name) => {
    try {
      return new Event(name);
    } catch (e) {
      const event = document.createEvent('Event');
      event.initEvent(name, false, false);
      return event;
    }
  };
  var findHiddenForRadio = function (root, radio) {
    var variationId = radio.getAttribute('data-variation-id');
    return root.querySelector('input[type="hidden"][data-variation-id="' + variationId + '"]');
  };

  var productVariations = function () {
    var self = this;
    const UNAVAILABLE_CLASS_VALUE = 'unavailable';

    var _config = {
      variationOptions: {
        selector: '[data-dropdown=productVariation]',
        containerSelector: '[data-variation-container=productVariations]',
        radioSelector: '[data-radio]',
        variationId: 'data-variation-id',
        masterProductId: 'data-master-id',
        childProductId: 'data-child-id',
        linkedProductId: 'data-linked-product-id',
        currentQuantityInBasket: 'data-information-current-quantity-basket',
        maximumAllowedQuantityInBasket: 'data-information-maximum-allowed-quantity',
        informationUrl: 'data-information-url',
        enableChildSkuDescription: 'data-enable-child-sku-description',
        linkedDropdown: '[data-linked-variation=true]',
        variationButtonsSelector: '[data-option-id]',
      },
      subscriptionComponent: {
        selector: '[data-component=subscriptionComponent]',
      },
      colorSwatches: {
        selector: '[data-color-swatch]',
        dataSelected: 'data-selected',
        ariaLabel: 'aria-label',
        selectedClassLight: 'productVariations_colorSwatch-selected-light',
        selectedClassDark: 'productVariations_colorSwatch-selected-dark',
        selectedClassImageSwatch: 'productVariations_imageSwatch-selected',
        borderedClass: 'productVariations_colorSwatch-bordered',
        dataValueId: 'data-value-id',
        dataDisabled: 'data-disabled',
        colorHexSumThreshold: 48,
        linkProductId: 'data-linked-product-id',
        optionUnavailable: 'data-option-unavailable',
        hasImageSwatches: 'data-image-swatch',
      },
      colorDropdown: {
        selector: '[data-dropdown-type=color]',
      },
      subscribeChannels: {
        variations: 'productVariations/variationData',
        image: 'productSingleImage/newImage',
        imageMultiple: 'productMultipleImages/newImage',
        price: 'productPrice/newPrice',
        tags: 'productTags/new',
        stock: 'productStockInformation/newStockInformation',
        releaseDate: 'productReleaseDate/newReleaseDate',
        addToBasketButton: 'productAddToBasketButton/newButton',
        moreInformation: 'productViewMoreInformation/newUrl',
        information: 'productInformation/newInformation',
        fullDescription: 'fullProductDescription/new',
        productQuantityInput: 'productQuantity/productQuantity',
        productName: 'productName/update',
        productPlatform: 'productPlatform/update',
        recentlyViewed: 'productRecentlyViewed/update',
        productReviews: 'productReviews/newProductReviews',
        productReviewStars: 'productReviewStars/update',
        productAddToWishlist: 'productAddToWishlist/update',
        productFrequentlyBoughtTogether: 'productFrequentlyBoughtTogether/update',
        loyaltyPointsMessage: 'loyaltyPointsMessage/update',
        fastTrackSwitchStateChange: 'fastTrackSwitch/stateChange',
        fastTrackSwitchUpdate: 'fastTrackSwitch/update',
        instalmentInfo: 'productInstalmentPayment/newInstalmentInfo',
        papUpdate: 'pap/update',
        BNPLProvidersInfo: 'productBuyNowPayLaterProviders/providersInfo',
        disableQuantityInput: 'productQuantityInput/disable',
        subscribeAndSaveContractsUpdate: 'subscribeAndSaveContracts/update',
        outOfStockNotificationUpdate: 'outOfStockNotification/update'
      },
      publishChannels: {
        DSTracking: 'columbo/track'
      },
      externalSelectors: {
        productNameTitle: '.productName_title',
        productPrice: '.productPrice_price',
        quantityInputLabel: '[data-quantity-input-container]',
        quantityInput: '[data-quantity-input-container] input',
        fastTrackSwitch: '.fastTrack_onProductPage',
      },
      attrs: {
        disableWishlist: 'data-disable-wishlist',
        hasSelection: 'data-has-selection',
        uniqueVariationId: 'data-variation-unique-id',
        linkedChildProductId: 'data-linked-child-product-id',
        subscribeAndSave: 'data-subscribe-and-save'
      },
      trackingEventSubtype: {
        size: 'size_selector',
        shade: 'shade_selector',
        variationDropdown: 'product_variations_dropdown',
        colorSwatch: 'colorSwatchClicked'
      },
      announcementDelay: 500,
    };

    const _init = (element, userDriven) => {
      self.element = element;
      self.app = app;

      self.innerContainer = self.element.querySelector(self.config.variationOptions.containerSelector);
      self.disableWishlist = self.element.getAttribute(self.config.attrs.disableWishlist);
      self.childProductId = self.innerContainer.getAttribute(self.config.variationOptions.childProductId);
      self.linkedChildProductId = self.innerContainer.getAttribute(self.config.attrs.linkedChildProductId);
      self.linkedProductId = self.innerContainer.getAttribute(self.config.variationOptions.linkedProductId);
      self.hasSelection = self.innerContainer.getAttribute(self.config.attrs.hasSelection);
      self.informationUrl = self.innerContainer.getAttribute(self.config.variationOptions.informationUrl);
      self.enableChildSkuDescription = self.innerContainer.getAttribute(self.config.variationOptions.enableChildSkuDescription);
      self.masterProductId = self.element.getAttribute(self.config.variationOptions.masterProductId);
      self.colorSwatches = self.element.querySelectorAll(self.config.colorSwatches.selector);
      self.hasImageSwatches = self.colorSwatches.length && self.colorSwatches[0].hasAttribute(self.config.colorSwatches.hasImageSwatches);
      self.colorDropdown = self.element.querySelector(self.config.colorDropdown.selector);
      self.variationOptions = self.element.querySelectorAll(self.config.variationOptions.selector);
      self.linkedDropdown = self.element.querySelector(self.config.variationOptions.linkedDropdown);
      self.subscriptionComponentEl = self.element.querySelector(self.config.subscriptionComponent.selector);
      self.radios = self.element.querySelectorAll(self.config.variationOptions.radioSelector);
      self.variationButtons = self.element.querySelectorAll(self.config.variationOptions.variationButtonsSelector);
      self.backgroundColor = window.getComputedStyle(self.element).getPropertyValue('background-color');
      self.quantityInputData = {
        numberOfItemsInBasket: self.innerContainer.getAttribute(self.config.variationOptions.currentQuantityInBasket),
        maximumNumberOfItemsToAdd: self.innerContainer.getAttribute(self.config.variationOptions.maximumAllowedQuantityInBasket),
      };

      if (!userDriven) {
        app.subscribe(self.config.subscribeChannels.fastTrackSwitchStateChange, self.fastTrackSwitchStateChanged);
      }

      self.radioOnLoad();
      self.addEventListeners();
      self.updateVariationData();
      self.changeProductInformation();

      if (self.publishQuantityInputData(self.quantityInputData.numberOfItemsInBasket)
        && self.publishQuantityInputData(self.quantityInputData.maximumNumberOfItemsToAdd)) {
        app.publish(self.config.subscribeChannels.productQuantityInput, self.quantityInputData, true);
      }

      if (self.subscriptionComponentEl) {
        require(['subscriptionComponent'], (comp) => self.subscriptionComponent = comp);
      }

      return self;
    };

    const _addEventListeners = () => {
      if (self.colorDropdown) {
        self.colorDropdown.addEventListener('change', self.changeColorDropdown);
        self.selectSwatchOnLoad(self.colorDropdown.value);
      }

      for (let i = 0, l = self.colorSwatches.length; i < l; i++) {
        const thisColorSwatch = self.colorSwatches[i];
        let thisDisabled = thisColorSwatch.getAttribute(self.config.colorSwatches.dataDisabled) !== null;

        if (!thisDisabled) {
          thisColorSwatch.addEventListener('click', () => self.clickColorSwatch(thisColorSwatch));
        }

        if (self.colorIsSameAsBg(thisColorSwatch)) {
          self.addBorderToColorSwatch(thisColorSwatch);
        }
      }

      for (let i = 0, l = self.variationOptions.length; i < l; i++) {
        self.variationOptions[i].addEventListener('change', self.variationsChange);
      }

      for (let i = 0, l = self.variationButtons.length; i < l; i++) {
        self.variationButtons[i].addEventListener('click', self.setVariation);
      }

      for (let i = 0; i < self.radios.length; i++) {
        let radio = self.radios[i];
        radio.addEventListener('change', self.radioChange.bind(null, radio));
      }

      if (self.linkedDropdown) {
        self.linkedDropdown.addEventListener('change', self.linkedDropdownChange);
      }
    };

    const _isIpadDevice = () => {
      return (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2);
    };

    const _setVariation = function (e) {
      const target = e.currentTarget;
      const hiddenInput = document.querySelector(`input[data-variation-id="${target.getAttribute('data-variation-id')}"]`);
      if (hiddenInput && target) {
        hiddenInput.value = target.getAttribute('data-option-id');

        self.updateFallbackProductId(
          target.getAttribute(self.config.variationOptions.linkedProductId),
          target.classList.contains(UNAVAILABLE_CLASS_VALUE)
        );

        hiddenInput.dispatchEvent(createEvent('change'));
        self.elementToReturnFocusTo = target;
      }

      let targetId = target.hasAttribute(self.config.variationOptions.linkedProductId)
      && target.getAttribute(self.config.variationOptions.linkedProductId) !== 'default'
          ? target.getAttribute(self.config.variationOptions.linkedProductId)
          : self.innerContainer.getAttribute(self.config.variationOptions.childProductId);
      app.publish(self.config.publishChannels.DSTracking, self.config.trackingEventSubtype.size, 'click', targetId);
    };

    const _variationsChange = (e, updateChangedComps, nullSelection) => {
      if (!self.isIpadDevice) {
        self.elementToReturnFocusTo = !self.elementToReturnFocusTo ? e.target || e : self.elementToReturnFocusTo;
      } else {
        self.elementToReturnFocusTo = e.target || e;
      }

      self.updateFallbackProductId(null, null, e);

      // update masterProductId value that's sent to backend
      // to retrieve child product from current selections
      // only if linked skus are turned on and it's valid sku
      if (self.currentLinkedProductId
          && self.currentLinkedProductId !== 'default'
          && self.currentLinkedProductId !== '0') {
        self.masterProductId = self.currentLinkedProductId;
      }

      let postUrl = '/' + self.masterProductId + '.variations?';

      if (self.enableChildSkuDescription !== '')
        postUrl = postUrl.concat(`&enableChildProductDescription=${self.enableChildSkuDescription}`);
      let fastTrack = document.querySelector(self.config.externalSelectors.fastTrackSwitch);

      if (fastTrack) {
        postUrl += '&useCookieForFastTrackFiltration='
          + fastTrack.getAttribute('data-switch-enabled');
      }

      let enableSubscribeAndSave = self.innerContainer.getAttribute(self.config.attrs.subscribeAndSave);
      if (enableSubscribeAndSave) {
        postUrl += '&enableSubscribeAndSave='
          + enableSubscribeAndSave;
      }

      if (new URLSearchParams(window.location.search).get('variationsUseImageSwatches') === 'true') {
        postUrl += '&variationsUseImageSwatches=true'
      }

      app.ajax.post({
        url: postUrl,
        send: nullSelection ? null : JSON.stringify(self.constructPostData()),
        requestHeader: {
          header: 'Content-Type',
          value: 'application/json',
        },
        success: updateChangedComps === true ? self.variationSuccessHandlerForFastTrack : self.variationSuccessHandler,
        error: self.variationErrorHandler,
      });
    };

    const _radioOnLoad = () => {
      [].filter.call(self.radios, radio => radio.checked).forEach(function (checkedRadio) {
        findHiddenForRadio(self.element, checkedRadio).value = checkedRadio.value;
      });
    };

    const _radioChange = (radio) => {
      if (!radio.checked) return;
      let hiddenInput = findHiddenForRadio(self.element, radio);
      hiddenInput.value = radio.value;

      if (!self.isIpadDevice) {
        self.elementToReturnFocusTo = radio;
      }

      self.updateFallbackProductId(
        radio.getAttribute(self.config.colorSwatches.linkProductId),
        radio.classList.contains(UNAVAILABLE_CLASS_VALUE)
      );

      hiddenInput.dispatchEvent(createEvent('change'));

    };

    const _constructPostData = () => {
      const postData = {
        selected: 0,
      };

      for (let i = 0, l = self.variationOptions.length; i < l; i++) {
        const input = self.variationOptions[i];
        postData['variation' + (i + 1)] = input.getAttribute(self.config.variationOptions.variationId);
        postData['option' + (i + 1)] = input.value;
        if (input.value) postData.selected++;
      }

      return postData;
    };

    const _updateProductPageComponents = (linkedProductId) => {
      if (linkedProductId  && linkedProductId !== 'default') {
        self.updateLinkedSkuChannels(linkedProductId);
      }

      if (self.unavailableOptionClicked) {
        self.updateGeneralComponents(self.linkedChildProductId);
      } else {
        self.updateGeneralComponents(self.childProductId);
      }
    };

    const _updateGeneralComponents = (productId) => {
      self.changeProductImage(productId);
      self.changeProductPrice(productId);
      self.changeReleaseDate(productId);
      self.changeProductTags(productId);
      self.changeProductViewMoreInformation(productId);
      self.updateSubscriptionComponent();
      self.changeProductInformation(productId);
      self.updateFastTrackSwitch(productId);
      self.changeLoyaltyPoints(productId);
      self.changeProductInstalmentPayment(productId);
      self.changeProductBNPLProviders(productId);
      self.changeProductStockInformation(productId);
      self.changeProductQuantityInput();
      if (self.enableChildSkuDescription === 'true') {
        self.updateFullProductDescription(productId, self.enableChildSkuDescription);
      }
      self.changeAddToBasketButton(productId);
      if (!self.disableWishlist) {
        self.changeProductAddToWishlist(productId);
      }
      self.updateSubscribeAndSaveContracts(productId);
      self.updateOutOfStockNotification(productId, self.unavailableOptionClicked);
    };


    const _variationSuccessHandler = (response) => {
      self.updateChildId(response);
      self.updateVariationData(response);
      self.updateProductPageComponents(self.currentLinkedProductId);

      if (self.elementToReturnFocusTo && !self.isIpadDevice) {
        const uniqueId = self.elementToReturnFocusTo.getAttribute(self.config.attrs.uniqueVariationId);
        const newElementToFocus = document.querySelector(`[${self.config.attrs.uniqueVariationId}="${uniqueId}"]`);
        accessibilityFocusHelper.focus(newElementToFocus);
        self.elementToReturnFocusTo = false;
      }

      $window.setTimeout(() => {
        self.announceNewProductInformation();
      }, self.config.announcementDelay);
    };

    const _announceNewProductInformation = () => {
      const getText = (element) => {
        return element ? element.innerText.trim().replace(/\n/g, ' ') : '';
      };

      const getValue = (element) => {
        return element ? element.value : '';
      };

      const productName = getText(document.querySelector(self.config.externalSelectors.productNameTitle));
      const price = getText(document.querySelector(self.config.externalSelectors.productPrice));
      const quantityLabel = getText(document.querySelector(self.config.externalSelectors.quantityInputLabel));
      const quantityValue = getValue(document.querySelector(self.config.externalSelectors.quantityInput));

      let message = `${productName} ${price},`;

      const productVariations = self.element.querySelectorAll('[data-product-variation]');

      productVariations.forEach((variation) => {
        const variationElement = variation.querySelector('[data-product-variation-type]');
        const variationType = variationElement.getAttribute('data-product-variation-type');

        if (variationType === 'radio') {
          const label = getText(variationElement.querySelector('label'));
          const value = getText(variationElement.querySelector('button[data-selected]'));
          message = `${message} ${label} ${value},`;
        }
        else if (variationType === 'color' || variationType === 'dropdown') {
          const label = getText(variationElement.querySelector('label'));
          const option = variationElement.querySelector('option[selected]');
          const value = option && option.innerText ? getText(option) : '';
          message = `${message} ${label} ${value},`;
        }
        else if (variationType === 'cards') {
          const selectedCardInput = variationElement.querySelector('[checked][data-announce-value]');
          const announceValue = selectedCardInput && selectedCardInput.getAttribute('data-announce-value');
          message = `${message} ${announceValue},`;
        }
      });

      message = `${message} ${quantityLabel} ${quantityValue}`;

      app.publish('accessibility/announce', 'assertive', message);
    };

    const _variationErrorHandler = () => {
      console.error(
        'ERROR: Could not retrieve variation data for product: ' + self.masterProductId);
    };

    const _updateChildId = (response) => {
      let newContainer = document.createElement('html');
      newContainer.innerHTML = response;
      let newElem = newContainer.querySelector(self.config.variationOptions.containerSelector);
      let oldElem = self.element.querySelector(self.config.variationOptions.containerSelector);
      self.element.removeChild(oldElem);
      self.element.appendChild(newElem);
      let parent = newElem.parentElement;

      // update masterProductId if it's linked sku
      let linkedProductId = newElem.getAttribute(self.config.variationOptions.linkedProductId);
      if (linkedProductId !== '0') {
        parent.setAttribute(self.config.variationOptions.masterProductId, linkedProductId);
      }

      self.init(parent, true);
      app.publish(self.config.subscribeChannels.productQuantityInput, self.quantityInputData, true);
    };

    const _updateVariationData = () => {
      const variationData = {
        variationLength: self.variationOptions.length,
        variations: [],
      };

      for (let i = 0; i < self.variationOptions.length; i++) {
        let thisVariationDropdown = self.variationOptions[i];
        if (!thisVariationDropdown.value) continue;
        variationData.variations.push(thisVariationDropdown.value);
      }

      app.publish(self.config.subscribeChannels.variations, variationData, true);
    };

    const _changeProductImage = (productId) => {
      app.publish(self.config.subscribeChannels.image, productId, true);
      app.publish(self.config.subscribeChannels.imageMultiple, {
        productId: self.childProductId,
        variation: false,
        personalised: false
      });
    };

    const _changeProductPrice = (productId) => {
      app.publish(self.config.subscribeChannels.price, productId, true);
    };

    const _changeProductInstalmentPayment = (productId) => {
      app.publish(self.config.subscribeChannels.instalmentInfo, productId, true);
    };

    const _changeProductBNPLProviders = (productId) =>
      app.publish(self.config.subscribeChannels.BNPLProvidersInfo, productId, true);

    const _changeProductStockInformation = (productId) => {
      app.publish(self.config.subscribeChannels.stock, productId, true);
    };

    const _changeReleaseDate = (productId) => {
      app.publish(self.config.subscribeChannels.releaseDate, productId, true);
    };

    const _changeAddToBasketButton = (productId) => {
      app.publish(self.config.subscribeChannels.addToBasketButton,
        productId,
        self.unavailableOptionClicked && self.linkedChildProductId === self.masterProductId);
    };

    const _changeProductTags = (productId) => {
      app.publish(self.config.subscribeChannels.tags, productId, true);
    };

    const _changeProductViewMoreInformation = () => {
      app.publish(self.config.subscribeChannels.moreInformation, self.informationUrl, true);
    };

    const _changeProductInformation = (productId) => {
      app.publish(self.config.subscribeChannels.information, productId, true);
    };

    const _updateReviews = (linkedProductId) => {
      app.publish(self.config.subscribeChannels.productReviews, linkedProductId, true);
    };

    const _updateReviewStars = (linkedProductId) => {
      app.publish(self.config.subscribeChannels.productReviewStars, linkedProductId, true);
    };

    const _updateProductName = (linkedProductId) => {
      app.publish(self.config.subscribeChannels.productName, linkedProductId, true);
    };

    const _updateProductPlatform = (linkedProductId) => {
      app.publish(self.config.subscribeChannels.productPlatform, linkedProductId);
    };

    const _updateFullProductDescription = (linkedProductId, enableChildSkuDescription) => {
      app.publish(self.config.subscribeChannels.fullDescription, linkedProductId, enableChildSkuDescription, true);
    };

    const _changeProductRecentlyViewed = (linkedProductId) => {
      app.publish(self.config.subscribeChannels.recentlyViewed, linkedProductId, true);
    };

    const _changeProductFrequentlyBoughtTogether = (linkedProductId) => {
      app.publish(self.config.subscribeChannels.productFrequentlyBoughtTogether, linkedProductId, true);
    };
    const _changeProductAddToWishlist = (productId) => {
      app.publish(self.config.subscribeChannels.productAddToWishlist,
        productId,
        self.unavailableOptionClicked && self.linkedChildProductId === self.masterProductId);
    };

    const _changeLoyaltyPoints = (productId) => {
      app.publish(self.config.subscribeChannels.loyaltyPointsMessage, productId);
    };

    const _updateFastTrackSwitch = (productId) => {
      app.publish(self.config.subscribeChannels.fastTrackSwitchUpdate, productId, true);
    };

    const _updatePap = (linkedProductId) => {
      app.publish(self.config.subscribeChannels.papUpdate, linkedProductId);
    };

    const _updateOutOfStockNotification = (linkedProductId, unavailableOptionClicked) => {
      app.publish(self.config.subscribeChannels.outOfStockNotificationUpdate, linkedProductId, unavailableOptionClicked);
    };

    const _changeProductQuantityInput = () => {
      app.publish(self.config.subscribeChannels.disableQuantityInput,
        self.unavailableOptionClicked && self.linkedChildProductId === self.masterProductId);
    };

    const _updateSubscribeAndSaveContracts = (productId) => {
      app.publish(self.config.subscribeChannels.subscribeAndSaveContractsUpdate, productId);
    };

    const _colorIsSameAsBg = (colorSwatch) => {
      return self.backgroundColor === window.getComputedStyle(colorSwatch)
        .getPropertyValue('background-color');
    };

    const _selectSwatchOnLoad = (value) => {
      if (value && value !== '') {
        self.updateColorSwatch(value, false);
      }
    };

    const _addBorderToColorSwatch = (colorSwatch) => {
      app.element.addClass(self.config.colorSwatches.borderedClass, colorSwatch);
    };

    const _deselectAllColorSwatches = () => {
      for (let i = 0, l = self.colorSwatches.length; i < l; i++) {
        let thisColorSwatch = self.colorSwatches[i];
        app.element.setAttribute(self.config.colorSwatches.dataSelected, 'false', thisColorSwatch);
        app.element.removeClass(self.config.colorSwatches.selectedClassLight, thisColorSwatch);
        app.element.removeClass(self.config.colorSwatches.selectedClassDark, thisColorSwatch);
        if(self.hasImageSwatches){
          app.element.removeClass(self.config.colorSwatches.selectedClassImageSwatch, thisColorSwatch);
        }
        self.removeSelectedLabel(thisColorSwatch);
      }
    };

    const _clickColorSwatch = (button) => {
      let optionUnavailable = app.element.getAttribute(self.config.colorSwatches.optionUnavailable, button);
      self.deselectAllColorSwatches();
      self.selectColorSwatch(button);
      self.updateColorDropdown(app.element.getAttribute(self.config.colorSwatches.dataValueId, button));
      self.updateFallbackProductId(
        app.element.getAttribute(self.config.colorSwatches.linkProductId, button),
        optionUnavailable === 'true');

      self.variationsChange(button);
      app.publish(self.config.publishChannels.DSTracking, self.config.trackingEventSubtype.shade, 'click',
          self.innerContainer.getAttribute(self.config.variationOptions.childProductId));
    };

    const _changeColorDropdown = () => {
      self.updateColorSwatch(this.value, true);
      app.publish(self.config.publishChannels.DSTracking, self.config.trackingEventSubtype.variationDropdown, 'click',
          self.innerContainer.getAttribute(self.config.variationOptions.childProductId));
    };

    const _updateColorDropdown = (value) => {
      self.colorDropdown.value = value;
    };

    const _selectColorSwatch = (color) => {
      app.element.setAttribute(self.config.colorSwatches.dataSelected, 'true', color);
      self.addSelectedLabel(color);

      let styleAttribute = color.getAttribute('style');
      let selectedSwatchColorHex = styleAttribute.split(': ')[1];

      if(self.hasImageSwatches){
        app.element.addClass(self.config.colorSwatches.selectedClassImageSwatch, color);
        return;
      }

      if (self.getColorSum(selectedSwatchColorHex) <= self.config.colorSwatches.colorHexSumThreshold) {
        app.element.addClass(self.config.colorSwatches.selectedClassLight, color);
      } else {
        app.element.addClass(self.config.colorSwatches.selectedClassDark, color);
      }
    };

    const _getColorSum = (color) => {
      let colorArray = color.split('');
      let sumColorHex = 0;
      for (let i = 0; i < colorArray.length; i++) {
        if (colorArray[i] >= '0' && colorArray[i] <= '9') {
          sumColorHex += Number(colorArray[i]);
        } else if (colorArray[i] >= 'a' && colorArray[i] <= 'f') {
          sumColorHex += Number(colorArray[i].charCodeAt(0) - 'a'.charCodeAt(0) + 10);
        } else if (colorArray[i] >= 'A' && colorArray[i] <= 'F') {
          sumColorHex += Number(colorArray[i].charCodeAt(0) - 'A'.charCodeAt(0) + 10);
        }
      }
      return sumColorHex;
    };

    const _updateColorSwatch = (value, updateComponents) => {
      self.deselectAllColorSwatches();

      for (let i = 0, l = self.colorSwatches.length; i < l; i++) {
        let thisColorSwatch = self.colorSwatches[i];
        if (app.element.getAttribute(self.config.colorSwatches.dataValueId, thisColorSwatch) === value) {
          if (updateComponents) {
            const linkedProductId = app.element.getAttribute(self.config.colorSwatches.linkProductId, thisColorSwatch);
            if (linkedProductId !== 'default') {
              self.updateLinkedSkuChannels(linkedProductId);
            }
          }
          self.selectColorSwatch(thisColorSwatch);
          return;
        }
      }
    };

    const _updateSubscriptionComponent = () => {
      self.subscriptionComponentEl = self.element.querySelector(self.config.subscriptionComponent.selector);

      if (!self.subscriptionComponentEl) {
        return;
      }

      self.subscriptionComponentEl.componentObject = new self.subscriptionComponent();
      self.subscriptionComponentEl.componentObject.init(self.subscriptionComponentEl);
    };

    const _linkedDropdownChange = () => {
      var selectedOption = self.linkedDropdown.options[self.linkedDropdown.selectedIndex];
      var linkedProductId = selectedOption.getAttribute(self.config.colorSwatches.linkProductId);

      if (linkedProductId && linkedProductId !== 'default') {
        self.updateLinkedSkuChannels(linkedProductId);
      }
    };

    const _updateLinkedSkuChannels = (linkedProductId) => {
      app.publish('tracking/record', 'Linked SKU Engagement',
        'Clicked away from ' + self.childProductId, 'Clicked on' + linkedProductId);
      app.publish(self.config.publishChannels.DSTracking, self.config.trackingEventSubtype.colorSwatch, 'click', linkedProductId);
      self.updateReviews(linkedProductId);
      self.updateReviewStars(linkedProductId);
      if (self.enableChildSkuDescription !== 'true')
        self.updateFullProductDescription(linkedProductId, self.enableChildSkuDescription);
      self.updateProductName(linkedProductId);
      self.updateProductPlatform(linkedProductId);
      self.changeProductRecentlyViewed(linkedProductId);
      self.changeProductFrequentlyBoughtTogether(linkedProductId);
      self.updatePap(linkedProductId);
    };

    const _addSelectedLabel = function (button) {
      const currentARIALabel = button.getAttribute(self.config.colorSwatches.ariaLabel);
      const newARIAlabel = `${currentARIALabel} selected`;
      app.element.setAttribute(self.config.colorSwatches.ariaLabel, newARIAlabel, button);
    };

    const _removeSelectedLabel = function (button) {
      const currentARIALabel = app.element.getAttribute(self.config.colorSwatches.ariaLabel, button);
      const newARIAlabel = currentARIALabel.replace(' selected', '');
      app.element.setAttribute(self.config.colorSwatches.ariaLabel, newARIAlabel, button);
    };

    const _fastTrackSwitchStateChanged = (productId, linkedProductId, switchState) => {
      let element = document.querySelector(self.config.externalSelectors.fastTrackSwitch);
      if (self.hasSelection !== 'true') {
        self.variationsChange(element, true, true);
      } else {
        if (switchState === 'true') {
          if (productId === self.childProductId) {
            self.variationsChange(element, true);
          } else {
            if (linkedProductId !== '0' && (linkedProductId !== self.linkedProductId)) {
              self.updateLinkedSkuChannels(linkedProductId);
            }
            self.retrieveFastTrackQualifiedVariations(productId);
          }
        } else {
          self.variationsChange(element, true);
        }
      }
    };

    const _retrieveFastTrackQualifiedVariations = (productId) => {
      let requestUrl = '/' + self.masterProductId
        + '.fasttrackupdate' + '?childProductId=' + productId
        + '&useCookieForFastTrackFiltration=true';

      app.ajax.get({
        url: requestUrl,
        success: self.variationSuccessHandler,
        error: self.variationErrorHandler,
      });
    };

    const _variationSuccessHandlerForFastTrack = (response) => {
      self.updateChildId(response);
      self.updateVariationData(response);
      self.changeProductPrice(self.childProductId);
    };

    const _publishQuantityInputData = (item) => {
      return item && item !== '0';
    };

    const _updateFallbackProductId = (productId, isUnavailable, e) => {
      if (e) {
        let option = e.currentTarget ?  e.currentTarget.options
          && e.currentTarget.options[e.currentTarget.selectedIndex] || e.currentTarget
          : e;

        if (option.getAttribute(self.config.variationOptions.linkedProductId)) {
          self.currentLinkedProductId = option.getAttribute(self.config.variationOptions.linkedProductId);
          self.unavailableOptionClicked = option.classList.contains(UNAVAILABLE_CLASS_VALUE);
        }
      } else {
        self.currentLinkedProductId = productId;
        self.unavailableOptionClicked = isUnavailable;
      }
    };


    self.init = _init;
    self.config = _config;
    self.selectSwatchOnLoad = _selectSwatchOnLoad;
    self.isIpadDevice = _isIpadDevice;
    self.variationsChange = _variationsChange;
    self.radioChange = _radioChange;
    self.radioOnLoad = _radioOnLoad;
    self.setVariation = _setVariation;
    self.variationSuccessHandler = _variationSuccessHandler;
    self.variationErrorHandler = _variationErrorHandler;
    self.constructPostData = _constructPostData;
    self.updateChildId = _updateChildId;
    self.addEventListeners = _addEventListeners;
    self.updateVariationData = _updateVariationData;
    self.changeProductImage = _changeProductImage;
    self.changeProductPrice = _changeProductPrice;
    self.changeProductStockInformation = _changeProductStockInformation;
    self.changeReleaseDate = _changeReleaseDate;
    self.changeAddToBasketButton = _changeAddToBasketButton;
    self.changeProductTags = _changeProductTags;
    self.changeProductViewMoreInformation = _changeProductViewMoreInformation;
    self.changeProductInformation = _changeProductInformation;
    self.changeLoyaltyPoints = _changeLoyaltyPoints;
    self.clickColorSwatch = _clickColorSwatch;
    self.deselectAllColorSwatches = _deselectAllColorSwatches;
    self.getColorSum = _getColorSum;
    self.updateColorDropdown = _updateColorDropdown;
    self.changeColorDropdown = _changeColorDropdown;
    self.updateColorSwatch = _updateColorSwatch;
    self.selectColorSwatch = _selectColorSwatch;
    self.colorIsSameAsBg = _colorIsSameAsBg;
    self.addBorderToColorSwatch = _addBorderToColorSwatch;
    self.updateSubscriptionComponent = _updateSubscriptionComponent;
    self.updateLinkedSkuChannels = _updateLinkedSkuChannels;
    self.updateReviews = _updateReviews;
    self.updateFullProductDescription = _updateFullProductDescription;
    self.updateProductName = _updateProductName;
    self.updateProductPlatform = _updateProductPlatform;
    self.changeProductRecentlyViewed = _changeProductRecentlyViewed;
    self.changeProductAddToWishlist = _changeProductAddToWishlist;
    self.changeProductFrequentlyBoughtTogether = _changeProductFrequentlyBoughtTogether;
    self.updateReviewStars = _updateReviewStars;
    self.announceNewProductInformation = _announceNewProductInformation;
    self.addSelectedLabel = _addSelectedLabel;
    self.removeSelectedLabel = _removeSelectedLabel;
    self.linkedDropdownChange = _linkedDropdownChange;
    self.fastTrackSwitchStateChanged = _fastTrackSwitchStateChanged;
    self.retrieveFastTrackQualifiedVariations = _retrieveFastTrackQualifiedVariations;
    self.variationSuccessHandlerForFastTrack = _variationSuccessHandlerForFastTrack;
    self.updateFastTrackSwitch = _updateFastTrackSwitch;
    self.publishQuantityInputData = _publishQuantityInputData;
    self.changeProductInstalmentPayment = _changeProductInstalmentPayment;
    self.changeProductBNPLProviders = _changeProductBNPLProviders;
    self.updatePap = _updatePap;
    self.updateGeneralComponents = _updateGeneralComponents;
    self.updateFallbackProductId = _updateFallbackProductId;
    self.updateProductPageComponents = _updateProductPageComponents;
    self.changeProductQuantityInput = _changeProductQuantityInput;
    self.updateSubscribeAndSaveContracts = _updateSubscribeAndSaveContracts;
    self.updateOutOfStockNotification = _updateOutOfStockNotification;
  };

  return productVariations;
});
